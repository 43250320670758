import * as React from "react"

import { graphql, useStaticQuery } from 'gatsby'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay  } from 'swiper/core'
import { Fade } from "react-awesome-reveal"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Helmet } from "react-helmet"
import BackgroundImage from 'gatsby-background-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faStar, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import favicon from '../images/ico.png'

import 'swiper/scss'

SwiperCore.use([Autoplay])

const navigation = [
	{ name: 'Beranda', href: '/#home' },
	{ name: 'Keunggulan', href: '/#keunggulan' },
	{ name: 'Testimoni', href: '/#testimoni' },
	{ name: 'Produk', href: '/#produk' },
	{ name: 'Hubungi', href: '/#hubungi' },
  ]

const IndexPage = () => {
  const data = useStaticQuery(
		graphql`
		  query {
			desktop: file(relativePath: { eq: "bg.png" }) {
			  childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}
		  }
		`
	  )
	
	const imageData = data.desktop.childImageSharp.fluid

    function handleClickWhatsapp() {
		window.gtag("event", "conversion", { send_to: ["AW-10865187007/3rqvCNvfhKkDEL-x9rwo"]})
	}
    function handleClickSocial() {
		window.gtag("event", "conversion", { send_to: ["AW-10865187007/UeMsCP_fhKkDEL-x9rwo"]})
	}
    function handleClickEmail() {
		window.gtag("event", "conversion", { send_to: ["AW-10865187007/XuvICILghKkDEL-x9rwo"]})
	}

  return (
          <>
            <Helmet>
                <title>Gerai Aki - Your solution for Car Battery</title>
                <link rel="icon" type="image/png" href={favicon} width={16} height={16}/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            </Helmet>
            <div id='home' className="flex flex-col w-full bg-white overflow-hidden">
                <div className="w-full">
                      <div className="bg-white">
                          <div className='mx-auto w-full lg:w-8/12'>
                              <Popover>
                                  {({ open }) => (
                                  <>
                                      <div className="flex flex-row justify-between py-2 px-6 lg:px-0">
                                          <div>
                                              <AnchorLink href="/">
                                                  <a>
                                                  <span className="sr-only">Gerai Aki</span>
                                                  <StaticImage src='../images/logo.png' alt="logo" height={45}/>
                                                  </a>
                                              </AnchorLink>
                                          </div>
                                          <nav
                                              className="flex items-center justify-between lg:justify-start"
                                              aria-label="Global"
                                          >
                                              <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                                              <div className="flex items-center justify-between w-full md:w-auto">
                                                  <div className="-mr-2 flex items-center md:hidden">
                                                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-main hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                                                      <span className="sr-only">Open main menu</span>
                                                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                                  </Popover.Button>
                                                  </div>
                                              </div>
                                              </div>
                                              <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                                              {navigation.map((item) => (
                                                  <AnchorLink className="font-large text-large hover:text-main nav-item" key={item.name} to={item.href}>
                                                    	{item.name}
                                                  </AnchorLink>
                                              ))}
                                              </div>
                                          </nav>
                                      </div>

                                      <Transition
                                      show={open}
                                      as={Fragment}
                                      enter="duration-500 ease-in-out"
                                      enterFrom="opacity-20 translate-x-11"
                                      enterTo="opacity-100 translate-x-0"
                                      leave="duration-500 ease-in-out"
                                      leaveFrom="opacity-100 translate-x-0"
                                      leaveTo="opacity-0 translate-x-full"
                                      >
                                          	<Popover.Panel
                                              focus
                                              static
                                              className="absolute right-0 inset-0 transition transform origin-right md:hidden z-20"
                                          	>
												<div className="bg-white ring-1 ring-black ring-opacity-5 overflow-hidden h-screen">
													<div className="px-5 pt-4 flex items-center justify-between">
														<div>
															<StaticImage src='../images/logo.png' alt="logo" height={45}/>
														</div>
														<div className="-mr-2">
														<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-main hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
															<span className="sr-only">Close main menu</span>
															<XIcon className="h-6 w-6" aria-hidden="true" />
														</Popover.Button>
														</div>
													</div>
													<div className="px-2 pt-2 pb-3 space-y-1">
														{navigation.map((item) => (
														<AnchorLink className="block px-3 py-2 rounded-md text-xl font-semibold text-main hover:text-gray-900 hover:bg-gray-50 nav-item"
															key={item.name}
															to={item.href}
														>
															{item.name}
														</AnchorLink>
														))}
													</div>
												</div>
                                          	</Popover.Panel>
                                      </Transition>
                                  </>
                                  )}
                              </Popover>
                          </div>
                      </div>
                </div>

            
				<BackgroundImage
					Tag="section"
					className='hero'
					fluid={imageData}
					backgroundColor={`#040e18`}
				>
					<div className="flex w-full items-center justify-center lg:w-10/12 h-full mx-auto">
						<div className=" flex flex-col items-center justify-center h-full m-2 lg:mx-24 px-10 lg:px-20 lg:mt-10 mx-auto">
							<Fade className='flex' triggerOnce='true'>
                                <StaticImage alt='gerai-aki' src='../images/gerai-aki.png' height={100}/>
								<p className='inline-flex text-white text-left font-bold text-xl md:text-3xl text-center'><i>MELAYANI KEBUTUHAN ACCU KENDARAAN ANDA</i></p>
                                <div className="flex flex-col md:flex-row justify-center items-center gap-3 mt-4">
                                    <OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=6281313454563&text=Halo%2C geraiaki.com" target="_blank" rel="noreferrer"  className="flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white bg-[#008137] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-48 text-lg">
                                        <FontAwesomeIcon icon={faWhatsapp} className='text-white w-5 h-5 mr-2 mt-1' /> <b>Whatsapp</b>
                                    </OutboundLink>
                                </div>
							</Fade>
						</div>
					</div>
				</BackgroundImage>

            <div className='flex flex-col bg-[#008137] pt-20'>
				<Fade className='flex' triggerOnce='true'>
					<div className='m-2 px-10 lg:mx-auto max-w-screen-xl pb-20'>
						<h2 className='text-white text-2xl lg:text-4xl font-bold text-center mb-4'><span>TENTANG</span> KAMI</h2>
						<p className='text-white text-xl text-left lg:text-center'><b>Gerai Aki</b> merupakan layanan jual aki mobil berkualitas dan bergaransi resmi.<br/><b>Gerai Aki</b> memiliki beberapa cabang di Jabodetabek, sehingga dapat memberikan layanan cepat antar aki ke lokasi Anda.</p>
					</div>
				</Fade>

                <div id='keunggulan' className='bg-white mx-auto w-full lg:w-10/12 py-20'>
                    <div className='m-2 lg:ml-24 lg:mr-24 px-10 lg:px-20'>
                        <h2 className='text-white text-2xl lg:text-4xl font-bold text-center mb-10 text-[#008137]'>KEUNGGULAN KAMI</h2>
                        <div className='flex flex-col lg:flex-row gap-8 grid grid-cols-2 lg:grid-cols-4'>
                            <div className='w-full flex flex-col border-2 border-white px-4 py-8 text-center mt-5'>
                                <div>
                                    <StaticImage alt='checklist-logo' src='../images/checklist-red.png' width={125} height={125}/>
                                </div>
                                <h3 className='text-[#008137] text-large lg:text-xl font-bold text-center'>RESPON CEPAT</h3>
                            </div>
                            <div className='w-full flex flex-col border-2 border-white px-4 py-8 text-center mt-5'>
                                <div>
                                    <StaticImage alt='checklist-logo' src='../images/checklist-red.png' width={125} height={125}/>
                                </div>
                                <h3 className='text-[#008137] text-large lg:text-xl font-bold text-center'>HARGA TERJANGKAU</h3>
                            </div>
                            <div className='w-full flex flex-col border-2 border-white px-4 py-8 text-center mt-5'>
                                <div>
                                    <StaticImage alt='checklist-logo' src='../images/checklist-red.png' width={125} height={125}/>
                                </div>
                                <h3 className='text-[#008137] text-large lg:text-xl font-bold text-center'>GRATIS ANTAR & PASANG</h3>
                            </div>
                            <div className='w-full flex flex-col border-2 border-white px-4 py-8 text-center mt-5'>
                                <div>
                                    <StaticImage alt='checklist-logo' src='../images/checklist-red.png' width={125} height={125}/>
                                </div>
                                <h3 className='text-[#008137] text-large lg:text-xl font-bold text-center'>PASTI ORIGINAL</h3>
                            </div>
                            <div className='w-full flex flex-col border-2 border-white px-4 py-8 text-center mt-5'>
                                <div>
                                    <StaticImage alt='checklist-logo' src='../images/checklist-red.png' width={125} height={125}/>
                                </div>
                                <h3 className='text-[#008137] text-large lg:text-xl font-bold text-center'>GARANSI RESMI</h3>
                            </div>
                            <div className='w-full flex flex-col border-2 border-white px-4 py-8 text-center mt-5'>
                                <div>
                                    <StaticImage alt='checklist-logo' src='../images/checklist-red.png' width={125} height={125}/>
                                </div>
                                <h3 className='text-[#008137] text-large lg:text-xl font-bold text-center'>MELAYANI AREA JABODETABEK</h3>
                            </div>
                            <div className='w-full flex flex-col border-2 border-white px-4 py-8 text-center mt-5'>
                                <div>
                                    <StaticImage alt='checklist-logo' src='../images/checklist-red.png' width={125} height={125}/>
                                </div>
                                <h3 className='text-[#008137] text-large lg:text-xl font-bold text-center'>TEKNISI BERPENGALAMAN</h3>
                            </div>
                            <div className='w-full flex flex-col border-2 border-white px-4 py-8 text-center mt-5'>
                                <div>
                                    <StaticImage alt='checklist-logo' src='../images/checklist-red.png' width={125} height={125}/>
                                </div>
                                <h3 className='text-[#008137] text-large lg:text-xl font-bold text-center'>DISKON TUKAR TAMBAH</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div id='testimoni' className='pt-10 pb-20 mx-auto w-full lg:w-10/12'>
                    <div className='m-2 lg:m-24 px-10 lg:px-20'>
                        <h2 className='text-2xl lg:text-4xl font-bold text-center text-white'>TESTIMONIAL KAMI</h2>
                        <hr className='my-8 border-white'/>

                        <div className='mx-auto m-2'>
						<Swiper
						slidesPerView={1}
						autoplay={{
							"delay": 4000,
							"disableOnInteraction": false
						}} 
                        breakpoints={{
                            1024: {
                              slidesPerView: 2,
                            },
                        }}
						>
							<SwiperSlide>
								<div className='bg-[#008137]'>
                                    <div className="flex flex-row justify-center items-center">
                                        <div className="">
                                            <StaticImage className="rounded-full" alt='testimonial' src='../images/adam.png' width={75} height={75}/>
                                        </div>
                                        <div className="text-white">
                                            <div className="mt-2 font-semibold">
                                                Adam
                                            </div>
                                            <div className="rating text-center mt-2 mx-5 text-yellow-500">
                                                <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" />
                                            </div>
                                        </div>
                                    </div>
									<p className='mt-2 mx-5 text-center text-white'>Harga terjangkau dibanding toko lain, garansi resmi 1 tahun pula. Semoga berkah dan makin maju untuk Gerai Aki.</p>
									{/* <p className="mt-3 mx-5 text-left"> <b>Andi</b></p> */}
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='bg-[#008137]'>
                                    <div className="flex flex-row justify-center items-center">
                                        <div className="">
                                            <StaticImage className="rounded-full" alt='testimonial' src='../images/arif.png' width={75} height={75}/>
                                        </div>
                                        <div className="">
                                            <div className="mt-2 font-semibold text-white">
                                                Arif
                                            </div>
                                            <div className="rating text-center mt-2 mx-5 text-yellow-500">
                                                <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" />
                                            </div>
                                        </div>
                                    </div>
									<p className='mt-2 mx-5 text-center text-white'>Lokasi toko nya sangat strategis, berada tepat di pinggir jalan utama, banyak terdapat pilihan tipe dan jenis aki, harganya pun lebih terjangkau dibanding toko lain. Sangat recommended.</p>
									{/* <p className="mt-3 mx-5 text-left"><b>Wahyu</b></p> */}
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='bg-[#008137]'>
                                    <div className="flex flex-row justify-center items-center">
                                        <div className="">
                                            <StaticImage className="rounded-full" alt='testimonial' src='../images/fahri.png' width={75} height={75}/>
                                        </div>
                                        <div>
                                            <div className="mt-2 font-semibold text-white">
                                                Fahri
                                            </div>
                                            <div className="rating text-center mt-2 mx-5 text-yellow-500">
                                                <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" />
                                            </div>
                                        </div>
                                    </div>
									<p className='mt-2 mx-5 text-center text-white'>CS nya ramah dan berpengalaman. Teknisinya juga tanggap dan komunikatif. Benar-benar memberikan layanan yang baik ke customer. Semoga makin maju Gerai Aki !</p>
									{/* <p className="mt-3 mx-5 text-left"><b>Erwin</b></p> */}
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='bg-[#008137]'>
                                    <div className="flex flex-row justify-center items-center">
                                        <div className="">
                                            <StaticImage className="rounded-full" alt='testimonial' src='../images/joe.png' width={75} height={75}/>
                                        </div>
                                        <div>
                                            <div className="mt-2 font-semibold text-white">
                                                Joe
                                            </div>
                                            <div className="rating text-center mt-2 mx-5 text-yellow-500">
                                                <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" />
                                            </div>
                                        </div>
                                    </div>
									<p className='mt-2 mx-5 text-center text-white'>Awalnya ragu untuk order aki secara online. Setelah dicoba ternyata beneran langsung diantar dan dipasangkan. Cepat dan tanggap lagi. Semoga makin sukses!</p>
									{/* <p className="mt-3 mx-5 text-left"><b>Erwin</b></p> */}
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='bg-[#008137]'>
                                    <div className="flex flex-row justify-center items-center">
                                        <div className="">
                                            <StaticImage className="rounded-full" alt='testimonial' src='../images/ridho.png' width={75} height={75}/>
                                        </div>
                                        <div>
                                            <div className="mt-2 font-semibold text-white">
                                                Ridho
                                            </div>
                                            <div className="rating text-center mt-2 mx-5 text-yellow-500">
                                                <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" />
                                            </div>
                                        </div>
                                    </div>
									<p className='mt-2 mx-5 text-center text-white'>Responnya cepat dan pengirimannya juga cepat, puas pokoknya dengan pelayanan dari Gerai Aki. Terima Kasih, Sukses terus..</p>
									{/* <p className="mt-3 mx-5 text-left"><b>Erwin</b></p> */}
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='bg-[#008137]'>
                                    <div className="flex flex-row justify-center items-center">
                                        <div className="">
                                            <StaticImage className="rounded-full" alt='testimonial' src='../images/rizki.png' width={75} height={75}/>
                                        </div>
                                        <div>
                                            <div className="mt-2 font-semibold text-white">
                                                Rizki
                                            </div>
                                            <div className="rating text-center mt-2 mx-5 text-yellow-500">
                                                <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" />
                                            </div>
                                        </div>
                                    </div>
									<p className='mt-2 mx-5 text-center text-white'>Keadaan Pandemi seperti ini, emang sangat dibutuhkan layanan seperti ini. Tinggal telpon, aki langsung diantar dan dipasangkan, sangat sangat praktis. Thank you, Gerai Aki.</p>
									{/* <p className="mt-3 mx-5 text-left"><b>Erwin</b></p> */}
								</div>
							</SwiperSlide>
						</Swiper>
                        </div>
                    </div>
                </div>

                <div id='produk' className='bg-white mx-auto w-full lg:w-10/12 py-20'>
                    <div className='m-2 md:mx-12 lg:mx-24 px-10 md:px-10 lg:px-20'>
                        <h2 className='text-white text-2xl lg:text-4xl font-bold text-center mb-10 text-[#008137]'>PRODUK KAMI</h2>
                        <div className='flex flex-col lg:flex-row gap-8 grid grid-cols-1 lg:grid-cols-3'>
                            <div className='w-full flex flex-col border-2 border-white px-4 py-16 text-center mt-5'>
                                <h3 className='text-white text-xl lg:text-2xl font-bold text-center text-[#008137]'>Aki Kering<br/>(Maintenance Free Battery)</h3>
                                <StaticImage alt='aki-kering' src='../images/aki-mf.png' height={250} width={425}/>
                                <div className='text-center mt-8'>
                                    <OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=6281313454563&text=Halo%2C geraiaki.com" target="_blank" rel="noreferrer"><button className='py-2 px-4 border border-transparent font-medium rounded-md text-white bg-[#008137] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-48 text-lg ' >Hubungi Kami</button></OutboundLink>
                                </div>
                            </div>
                            <div className='w-full flex flex-col border-2 border-white px-4 py-16 text-center mt-5'>
                                <h3 className='text-white text-xl lg:text-2xl font-bold text-center text-[#008137]'>Aki Basah<br/>(Conventional Battery)</h3>
                                <StaticImage alt='aki-kering' src='../images/aki-basah.png' height={250} width={425}/>
                                <div className='text-center mt-8'>
                                    <OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=6281313454563&text=Halo%2C geraiaki.com" target="_blank" rel="noreferrer"><button className='py-2 px-4 border border-transparent font-medium rounded-md text-white bg-[#008137] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-48 text-lg ' >Hubungi Kami</button></OutboundLink>
                                </div>
                            </div>
                            <div className='w-full flex flex-col border-2 border-white px-4 py-16 text-center mt-5'>
                                <h3 className='text-white text-xl lg:text-2xl font-bold text-center text-[#008137]'>Aki Hybrid<br/><span className="text-white">-</span></h3>
                                <StaticImage alt='aki-kering' src='../images/aki-hybrid.png' height={250} width={425}/>
                                <div className='text-center mt-8'>
                                    <OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=6281313454563&text=Halo%2C geraiaki.com" target="_blank" rel="noreferrer"><button className='py-2 px-4 border border-transparent font-medium rounded-md text-white bg-[#008137] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-48 text-lg'>Hubungi Kami</button></OutboundLink>
                                </div>
                            </div>
                        </div>
                        <p className="text-center">Gerai Aki menyediakan beberapa merk dengan kualitas yang baik, seperti GS Astra, Amaron, Solite, Faster dll.</p>
                    </div>
                </div>
            </div>

            <div className='flex flex-col bg-white'>
                <div id='brands' className='bg-white py-20 mx-auto w-full lg:w-10/12'>
                    <div className='m-2 lg:mx-24 px-10 lg:px-20'>
                        <h2 className='text-2xl lg:text-4xl font-bold text-center mb-10'>BRANDS <span className='text-[#008137]'>KAMI</span></h2>
                        <Swiper
							slidesPerView={2}
							spaceBetween={50}
							autoplay={{
								"delay": 2000,
								"disableOnInteraction": false
							}}
							breakpoints={{
								1024: {
								  slidesPerView: 4,
								},
							}}
							>
								<SwiperSlide>
									<StaticImage src="../images/gsastra.png" alt="gsastra" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/amaron.png" alt="amaron" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/bosch.png" alt="bosch" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/delkor.png" alt="delkor" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/faster.png" alt="faster-jp" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/furukawa.png" alt="furukawa" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/incoe.png" alt="incoe" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/massiv.png" alt="massiv" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/panasonic.png" alt="panasonic" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/solite.png" alt="solite" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/varta.png" alt="varta" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/yuasa.png" alt="yuasa" width={250} height={125} loading='{ lazy }' />
								</SwiperSlide>
						</Swiper>
                    </div>
                </div>

                <div id='hubungi' className='flex flex-col bg-[#008137] w-full lg:w-10/12 pt-20 mx-auto'>
                    <div className='m-2 lg:m-24 px-10 lg:px-20'>
                        <h2 className='text-white text-2xl lg:text-4xl font-bold text-center mb-8'>HUBUNGI KAMI</h2>
                        <div className="flex flex-col md:flex-row justify-center items-center gap-3 mb-8">
                            <OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=6281313454563&text=Halo%2C geraiaki.com" target="_blank" rel="noreferrer"  className="flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white bg-white text-[#008137] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-48 text-lg">
                                <FontAwesomeIcon icon={faWhatsapp} className='text-[#008137] w-5 h-5 mr-2 mt-1' /> <b>081313454563</b>
                            </OutboundLink>
                        </div>
                        <div className="flex flex-col md:flex-row justify-center items-center mb-8">
                            <OutboundLink onClick={handleClickEmail} href="mailto:geraiakijakarta@gmail.com" target="_blank" rel="noreferrer" className="text-white">
                                Email : <b>geraiakijakarta@gmail.com</b>
                            </OutboundLink>    
                        </div>
                        <div className="flex flex-col md:flex-row justify-center items-center mb-8">
                            <p className="text-white text-center font-semibold"><FontAwesomeIcon icon={faLocationDot} size="1x" />  Jakarta <FontAwesomeIcon icon={faLocationDot} size="1x" /> Bogor <FontAwesomeIcon icon={faLocationDot} size="1x" /> Depok<br/><FontAwesomeIcon icon={faLocationDot} size="1x" /> Tangerang <FontAwesomeIcon icon={faLocationDot} size="1x" /> Bekasi</p>
                        </div>
                        {/* <div className="flex flex-col md:flex-row justify-center items-center mb-8">
                            <iframe title="Google Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.957534369904!2d106.86096321477004!3d-6.399473795369621!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69eb3320465897%3A0xe9d772d4cdac6027!2sMaju%20Berkah%20Battery!5e0!3m2!1sid!2sid!4v1646134256611!5m2!1sid!2sid" width="100%" height="450" style={{border:0}} loading="lazy"></iframe>
                        </div> */}
                        <div className="flex flex-col md:flex-row justify-center items-center mb-8">
                            <OutboundLink onClick={handleClickSocial} href="https://www.instagram.com/geraiakijakarta/" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faInstagram} className='text-white w-10 h-10 mr-2 mt-1' />
                            </OutboundLink>
                            <OutboundLink onClick={handleClickSocial} href="https://www.facebook.com/people/Gerai-Aki/100088899579070/?mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faFacebook} className='text-white w-9 h-9 mr-2 mt-1' />
                            </OutboundLink>
                        </div>
                    </div>
                    <div className='footer text-center text-sm'>
                        <p className='text-white py-3'>&copy; 2022 <a className="hover:text-red-500" href="https://www.geraiaki.com">GeraiAki.com</a> All Right Reserved<br/>Developed by <a className='hover:text-red-500' href="https://www.kodemerah.com" target='_blank'>KodeMerah</a></p>
                    </div>
                </div>
            </div>
        </div>
        <OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=6281313454563&text=Halo%2C geraiaki.com" className="wa-float" target="_blank" rel="noreferrer">
            <FontAwesomeIcon className='wa-my-float' icon={faWhatsapp} />
        </OutboundLink>
    </>
  )
}

export default IndexPage
